import { SystemStatus } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";

export async function systemStatus__server__get(): Promise<SystemStatus> {
  const { appFirebaseAdminApp } = getServerHelpers();

  const systemStatus = (await appFirebaseAdminApp.database().ref("systemStatus").once("value")).val();

  return systemStatus;
}
systemStatus__server__get.auth = async (r: express.Request) => {
  await validateToken(r);
};
